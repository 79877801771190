<template>
  <div id="cos-add">
    <el-form label-width="80px">
      <el-form-item label="文件名称">
        <el-input v-model="fileName"></el-input>
      </el-form-item>

      <el-form-item>
        <input name="myFile" type="file" @change="getFile" />
      </el-form-item>
      <el-form-item label="预览">
        <span class="cosFileName" v-if="cosParam == 'image' || cosParam == 'video'">
          <span v-if="cos.url" @click="dialogShowFileVisible = true">{{ cos.fileName }}</span>
        </span>
        <span v-else>
          <a :href="cos.url" target="_blank">{{ cos.fileName }}</a>
        </span>
      </el-form-item>
      <el-form-item label="网址">
        <span> {{ cos.urlCopy }} </span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onAddCos">立即创建</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
      <el-dialog title="预览" :visible.sync="dialogShowFileVisible">
        <div slot="footer" class="dialog-footer">
          <div class="showFileCover" v-if="cosParam === 'image'">
            <img class="showFile" :src="cos.url" alt />
          </div>
          <div class="showFileCover" v-if="cosParam === 'video'">
            <video class="showFile" controls :src="cos.url">
              你的浏览器不支持
              <code>video</code> 标签.
            </video>
          </div>
          <el-button @click="dialogShowFileVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogShowFileVisible = false">确 定</el-button>
        </div>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import { cosApi } from "@/api/cosApi";
import COS from "cos-js-sdk-v5";
import { alertMessage } from "@/util/alertMessageUtil.js";

export default {
  data() {
    return {
      uploadFiles: null,
      dialogShowFileVisible: false,
      cosParam: "image",
      fileName: "",
      cos: {
        cosId: "",
        cosKey: "",
        keyPrefix: "",
        fileType: "",
        createTime: "",
        updateTime: "",
        isDeleted: "",
        fileName: "",
        url: "",
      },

      bucket: "",
      region: "",
    };
  },
  mounted: function() {
    // 获取bucket 和 region
    cosApi
      .getBucketRegion("public")
      .then((ret) => {
        let { code, data } = ret;
        if (code == 0) {
          this.bucket = data.bucket;
          this.region = data.region;
        }
      })
      .catch((err) => {
        this.$message.error(err.message);
      });
  },

  methods: {
    // 保存cos
    onAddCos() {
      this.cos.fileName = this.fileName;

      if (this.cos.fileName === "") {
        this.$message.error("名称不可为空");
        return;
      }
      if (!this.uploadFiles) {
        this.$message.error("没选中文件...");
        return;
      }
      const loading = this.$loading({
        lock: true,
        target: "section",
        text: "上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      var cosClient = new COS({
        getAuthorization: function(options, callback) {
          var url = cosApi.COS_STS_URL_PUB;
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function(e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {
              console.log(e);
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              XCosSecurityToken: credentials.sessionToken,
              ExpiredTime: data.expiredTime,
            });
          };
          xhr.send();
        },
      });
      let file = this.uploadFiles[0];
      // 分片上传文件
      let _this = this;
      cosClient.sliceUploadFile(
        {
          Bucket: this.bucket,
          Region: this.region,
          Key: this.cos.cosKey,
          Body: file,
        },
        function(err, data) {
          console.log(err, data);
          if (data.statusCode == 200) {
            cosApi
              .addPub(_this.cos)
              .then((ret) => {
                let { code, msg, data } = ret;
                if (code == 0) {
                  _this.cos = data;
                  _this.cos.urlCopy = _this.cos.url;
                  if (_this.cos.url.startsWith("//")) {
                    _this.cos.urlCopy = _this.cos.url.substring(2);
                  }
                  loading.close();
                  alertMessage("保存成功", "success");
                } else {
                  alertMessage(msg, "warning");
                }
              })
              .catch((err) => {
                loading.close();
                alertMessage("保存失败，联系管理员" + err, "warning");
              });
          }
        }
      );
    },
    onCancel() {
      this.$router.push({
        path: "/pub",
      });
    },
    // 选择了文件
    getFile(e) {
      this.cos.url = "";
      this.uploadFiles = e.target.files;
      this.cos.fileName = this.uploadFiles[0].name;
      cosApi
        .getPubHolder(this.cos.fileName)
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0) {
            this.cos = data;
            this.cos.url = "";
            //用户输入了文件名，以输入为准，否则以选择的文件名为准
            if (this.fileName) {
              this.cos.fileName = this.fileName;
            } else {
              this.fileName = this.cos.fileName;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
#cos-add {
  margin: 20px;
  width: 60%;
  min-width: 600px;
}

.showFileCover {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.showFileCover :hover {
  cursor: pointer;
}

.cosFileName {
  border-bottom: 1px solid blue;
}

.cosFileName :hover {
  cursor: pointer;
}

.showFile {
  width: 320px;
  height: 320px;
}
</style>
